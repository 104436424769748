
export default {
  name: "NeedHelpButton",
  data() {
    return {
      link: "https://lin.ee/bkli1RI",
      images: [
        "/images/line-contact/241224_02-01.svg",
      ],
      currentDesktopImage: "",
      index: 0,
    };
  },
  methods: {
    startImageRotation() {
      this.updateImages();
    },
    updateImages() {
      this.currentDesktopImage = this.images[this.index];
    },
  },
  mounted() {
    this.startImageRotation();
  },
};

